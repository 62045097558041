import React from 'react';
import { Layout ,PageHeader} from 'antd';
import Sidebar from '@components/Sidebar/Sidebar';
import Header  from '@components/Header/Header';
import TimeTrack from '../components/TimeTrack/TimeTrack';

const { Footer, Content } = Layout;

const DasboardLayout = ({children}) => {

    return (
        <Layout style={{minHeight:'100vh'}}>
            <Sidebar />
            <Layout>
                <Header />
                <Content>
                    {children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>LMT by <a href="https://twntydigital.de" target="_blank">twnty</a>🚀 | v1.4.1 </Footer>
            </Layout>
        </Layout>
    )
}

export default DasboardLayout
