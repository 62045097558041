import React from 'react'
import { StyledHeader, Controls } from './styled';
import User from './User/User';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { Tag } from 'antd'


const Header = () => {

  let date = new Date();

  return (
    <StyledHeader>
      <Controls>
        {/* <span><Tag color="#87d068">{date.getHours() + ":" + date.getMinutes()}</Tag></span> */}
        {/* <Controls.Item> */}
        <User />
        {/* </Controls.Item> */}

        {/* <Controls.Item> */}
        <LanguageSelector />
        {/* </Controls.Item> */}

      </Controls>
    </StyledHeader>
  )
}

export default Header
//