import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {message} from "antd";

const PrivateRoute = ({ component: Component, roles=[], ...rest }) => {
    const auth = useSelector(state=>state.auth)
    const currentUser = useSelector(state=>state.me.data)
    if(Object.keys(currentUser).length==0) return false

    let userRoles = currentUser.roles
    userRoles = userRoles.map(role=>role.name)

  return (
    <Route {...rest} render={props => {
      if (!auth.authenticated) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }

      // check if route is restricted by role
      if (roles.length && !userRoles.some(role => roles.includes(role))) {
          // role not authorised so redirect to home page
          message.error('Not available for your role!')
          return <Redirect to={{ pathname: '/dashboard' }} />

      }

      // authorised so return component
      return <Component {...props} />
  }} />

)}

export default PrivateRoute
