import fetch from '../utilities/fetch'


const EmailAddress = {
    getAll: (page = 1, sorter = null, order = null, search_text = null)  => 
        fetch(`emailaddresses?page=${page}&sorter=${sorter}&order=${order}&search_text=${search_text}`),
    get:    (id)        => fetch(`emailaddresses/${id}`),
    create: (body)      => fetch('emailaddresses/','POST', body),
    delete: (id)        => fetch(`emailaddresses/${id}`,'DELETE'),
    update: (id,body)   => fetch(`emailaddresses/${id}`,'PATCH', body),
    search: (text)      => fetch(`emailaddresses?search=${text}`)
}

export default EmailAddress
