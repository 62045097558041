import fetch from '../utilities/fetch'


const Account = {
    getAll: (params='') => fetch(`accounts${params}`),
    get:    (id)        => fetch(`accounts/${id}`),
    create: (body)      => fetch('accounts/','POST', body),
    delete: (id)        => fetch(`accounts/${id}`,'DELETE'),
    update: (id,body)   => fetch(`accounts/${id}`,'PATCH', body)
}

export default Account