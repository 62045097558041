import React from 'react';
import useRoutes from './views/routes/routes';
import 'antd/dist/antd.css';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from './store/reducers/meReducer';
import { getStaticTranslations } from './store/reducers/app/translateReducer';
import WidgetBar from '@components/WidgetBar/WidgetBar';
import GlobalTimer from '@components/TimeTrack/GlobalTimer';

function AdminApp() {
  const authenticated = useSelector(state => state.auth.authenticated)
  const lang = useSelector(state => state.app.settings)
  const routes = useRoutes(authenticated)
  const dispatch = useDispatch()

  React.useEffect(()=>{
    dispatch(getUserData())
  },[authenticated])

  React.useEffect(()=>{
    dispatch(getStaticTranslations(
        [
          'dashboard','forum_management','forums','user_management','users',
          'roles','statistics','system','settings','translations','accounts','email_addresses',
          'customers', 'orders', 'packages', 'links'
        ]))
  },[lang])

    return (
      <>
        {routes}
        <GlobalTimer />
        <WidgetBar />
      </>

    );
}

export default AdminApp;

