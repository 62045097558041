import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { User } from '@api';

export const getUserData = createAsyncThunk('me/data', async (user, thunkApi) => {
  const {data} = await User.me()
  return data
})


const initialState = {
  data:{},
  isLoading:false
}
const me = createSlice({
  name: "me",
  initialState,
  reducers: {
  },
  extraReducers: {
    [getUserData.pending]: (state) => {
      state.isLoading = true
    },
    [getUserData.fulfilled]: (state,action) => {
      state.data = action.payload
      state.isLoading = false
    }
  },
});


export default me.reducer