import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter} from 'react-router-dom'

import store from './store/store'
import { Provider } from 'react-redux'
import AdminApp from './AdminApp';

if(document.getElementById('root')){ 
 
  ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <AdminApp />
        </BrowserRouter>
      </Provider>
    , document.getElementById('root')); 

    if (process.env.NODE_ENV === 'development' && module.hot) {
      module.hot.accept();
    }
}

