import React, { useState } from 'react'
import { Menu, Button, Layout } from 'antd';
import {
    DashboardOutlined,
    ContactsOutlined,
    UserOutlined,
    BarChartOutlined,
    MessageOutlined,
    ShoppingOutlined,
    SettingOutlined,
    ProfileOutlined,
    LinkOutlined,
    KeyOutlined,
    MailOutlined,
    ApartmentOutlined,
    TranslationOutlined,
    PieChartOutlined,
    RadarChartOutlined,
    TeamOutlined,
    StarOutlined,
    NotificationOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from './styled';
import routes from '../../routes';
import { useSelector } from 'react-redux';
import { admin } from '@configs/roles';
import PrivateMenuItem from './PrivateMenuItem';
import Access from "../Access";



const { Sider } = Layout;

const { SubMenu } = Menu;

const Sidebar = () => {
    const { pathname } = useLocation()
    const staticData = useSelector(state => state.app.translations.static)
    const currentUser = useSelector(state => state.me.data)
    const userRole = Object.keys(currentUser).length && currentUser?.roles[0]?.name

    if (!userRole) return false

    return (
        <Sider collapsible width="220px">
            <Link to="/dashboard">
                <Logo>LMT</Logo>
            </Link>
            <Menu
                theme="dark"
                selectedKeys={[pathname]}
                mode="inline"
            >
                <Menu.Item icon={<DashboardOutlined />} key={routes.dashboard}>
                    <Link to={routes.dashboard}>{staticData.dashboard}</Link>
                </Menu.Item>

                <Access forRoles={[admin]}>
                    <Menu.Item icon={<ContactsOutlined />} key={routes.customers.all}>
                        <Link to={routes.customers.all}>{staticData.customers}</Link>
                    </Menu.Item>
                </Access>


                <Access forRoles={[admin]}>
                    <Menu.Item icon={<ProfileOutlined />} key={routes.orders.all}>
                        <Link to={routes.orders.all}>{staticData.orders}</Link>
                    </Menu.Item>
                </Access>


                {/* ------------------------------------------------------ */}
                <Access forRoles={[admin]}>
                    <Menu.Item icon={<LinkOutlined />} key={routes.links.all}>
                        <Link to={routes.links.all}>{staticData.links}</Link>
                    </Menu.Item>
                </Access>

                {/* ------------------------------------------------------ */}
                <SubMenu key="sub1" icon={<MessageOutlined />} title={staticData.forum_management}>

                    <Menu.Item key={routes.forums.all} icon={<MessageOutlined />}>
                        <Link to={routes.forums.all}>{staticData.forums}</Link>
                    </Menu.Item>

                    <Menu.Item key={routes.accounts.all} icon={<KeyOutlined />}>
                        <Link to={routes.accounts.all}>{staticData.accounts}</Link>
                    </Menu.Item>

                    <Menu.Item key={routes.email_addresses.all} icon={<MailOutlined />}>
                        <Link to={routes.email_addresses.all}>{staticData.email_addresses}</Link>
                    </Menu.Item>

                </SubMenu>
                {/* ------------------------------------------------------ */}


                <Access forRoles={[admin]}>
                    <SubMenu key="sub2" icon={<UserOutlined />} title={staticData.user_management}>

                        <Menu.Item key={routes.users.all} icon={<UserOutlined />}>
                            <Link to={routes.users.all}>{staticData.users}</Link>
                        </Menu.Item>

                        <Menu.Item key={routes.roles} icon={<ApartmentOutlined />}>
                            <Link to={routes.roles}>{staticData.roles}</Link>
                        </Menu.Item>

                    </SubMenu>
                </Access>
                {/* ------------------------------------------------------ */}

                <Access forRoles={[admin]}>
                    <Menu.Item icon={<ShoppingOutlined />} key={routes.packages.all}>
                        <Link to={routes.packages.all}>{staticData.packages}</Link>
                    </Menu.Item>
                </Access>



                {/* ------------------------------------------------------ */}
                <SubMenu key="sub3" icon={<BarChartOutlined />} title={staticData.statistics}>
                    <Menu.Item icon={<StarOutlined />} key={routes.statistics.scores}>
                        <Link to={routes.statistics.scores}>Scores</Link>
                    </Menu.Item>

                    <Access forRoles={[admin]}>
                        <Menu.Item icon={<TeamOutlined />} key={routes.statistics.team}>
                            <Link to={routes.statistics.team}>Team</Link>
                        </Menu.Item>
                    </Access>

                    <Menu.Item icon={<PieChartOutlined />} key={routes.statistics.general}>
                        <Link to={routes.statistics.general}>General</Link>
                    </Menu.Item>

                    <Menu.Item icon={<NotificationOutlined />} key={routes.statistics.report}>
                        <Link to={routes.statistics.report}>Performance report</Link>
                    </Menu.Item>
                </SubMenu>
                {/* ------------------------------------------------------ */}

                <Access forRoles={[admin]}>
                    <SubMenu key="system" icon={<SettingOutlined />} title={staticData.system}>

                        <Menu.Item key={routes.system.settings} icon={<SettingOutlined />}>
                            <Link to={routes.system.settings}>{staticData.settings}</Link>
                        </Menu.Item>

                        <Menu.Item key={routes.system.translate} icon={<TranslationOutlined />}>
                            <Link to={routes.system.translate}>{staticData.translations}</Link>
                        </Menu.Item>

                    </SubMenu>
                </Access>


                {/* ------------------------------------------------------ */}
            </Menu>
        </Sider>
    )
}

export default Sidebar
