import styled from "styled-components"



export const WidgetBarBox = styled.div`
  position: fixed;
  right:50px;
  bottom:50px;
  padding: 5px 10px;
  background-color:#fff;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  display:flex;
  align-items:center;
`


