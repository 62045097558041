import fetch from '../utilities/fetch'

const Stat = {
  getGeneralStatistic: (params='') => fetch(`statistics/general${params}`),
  getTeamStatistic: (params='') => fetch(`statistics/team${params}`),
  getScores: (params='') => fetch(`statistics/scores${params}`),
  getReport: (params='')                    => fetch(`statistics/report${params}`)
}

export default Stat
