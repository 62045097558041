import React from 'react'
import PagetLayout from '@layouts/PagetLayout';
import {useSelector} from 'react-redux';
import {Card, Switch, Row, Col} from "antd";
import Setting from "@api/setting.api";
import {useLocation} from "react-router";

export const Settings = () => {
    const staticData = useSelector(state => state.app.translations.static);
    const [settings, setSettings] = React.useState([{value: false}])
    const [isLoading, setLoading] = React.useState(true);
    const location = useLocation()

    const handleActiveSwitcher = (value) => {
        Setting.update(settings[0].id, {value: value})
    }

    const getSettings = async () => {
        const data = await Setting.getAll()
        setSettings(data.data)
        setLoading(false)
    }

    React.useEffect(() => {
        getSettings()
    }, [location]);

    return (
        <PagetLayout
            loading={isLoading}
            header={{title: staticData.settings}}>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Change link automatic assignment">
                        <Switch checkedChildren="Workdays" unCheckedChildren="Any days"
                                defaultChecked={!!settings[0].value}
                                onChange={handleActiveSwitcher}/>
                    </Card>
                </Col>
                <Col>
                </Col>
            </Row>
        </PagetLayout>
    )
}


export default Settings
