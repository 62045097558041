import fetch from '../utilities/fetch'


const Forum = {
    getAll: (page = 1, sorter = null, order = null, search_text = null) =>
        fetch(`forums?page=${page}&sorter=${sorter}&order=${order}&search_text=${search_text}`),
    get:    (id)      => fetch(`forums/${id}`),
    create: (body)    => fetch('forums/','POST', body),
    delete: (id)      => fetch(`forums/${id}`,'DELETE'),
    update: (id,body)    => fetch(`forums/${id}`,'PATCH', body),
    search: (text)    => fetch(`forums?search=${text}`),
    getForumTopics: ()  => fetch(`forums?topics=yes`)

}

export default Forum
