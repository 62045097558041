import fetch from '../utilities/fetch'


const Package = {
  getAll: (params='')	=>  fetch(`packages${params}`),
  get:    (id)      	        => fetch(`packages/${id}`),
  create: (body)    	        => fetch('packages','POST',body),
  delete: (id)      	        => fetch(`packages/${id}`,'DELETE'),
  update: (id,body) 	        => fetch(`packages/${id}`,'PATCH',body),
  search: (text)                => fetch(`packages?search=${text}`)
}

export default Package
