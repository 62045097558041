import fetch from '../utilities/fetch'


const Customer = {
  getAll: (page = 1, sorter = null, order = null, search_text = null)	=> 
    fetch(`customers?page=${page}&sorter=${sorter}&order=${order}&search_text=${search_text}`),
  get:    (id)      	=> fetch(`customers/${id}`),
  create: (body)    	=> fetch('customers','POST',body),
  delete: (id)      	=> fetch(`customers/${id}`,'DELETE'),
  update: (id,body) 	=> fetch(`customers/${id}`,'PATCH',body),
  search: (text)      => fetch(`customers?search=${text}`)
}

export default Customer