import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import fetch from '../../utilities/fetch'

export const login = createAsyncThunk('auth/login', async (user, thunkApi) => {
  const {data,request} = await fetch('login','POST',user)
  if (request.status === 401) {
    return thunkApi.rejectWithValue(data)
  }else{
    localStorage.setItem('app_token', JSON.stringify(data));
    return data
  }
})

export const logout = createAsyncThunk('auth/logout', async (thunkApi) => {
  const {data,request} = await fetch('logout')
  if(request.status==200)
    localStorage.removeItem('app_token');
  return await data
})
 
const saved = JSON.parse(localStorage.getItem('app_token')) || {}

const initialState = {
  token: saved?.token,
  user:saved?.user,
  authenticated:saved?.authenticated || false,
  isLoading:false,
  error:false
}
const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state,action) => {
      state.token = {}
      state.user = {}
      state.authenticated = false
      state.isLoading = true
      state.error = false
    },
    [login.fulfilled]: (state,action) => {
      state.token = action.payload.token
      state.user = action.payload.user
      state.authenticated = action.payload.authenticated
      state.isLoading = false
    },
    [login.rejected]: (state,action) => {
      state.error = action.payload
      state.isLoading = false
    },
    [logout.pending]: (state,action) => {
      state.authenticated = false
    },
    [logout.fulfilled]: (state,action) => {
      state.token = {}
      state.user = {}
      state.authenticated = false
    }
  },
});




export default auth.reducer;