import React, { useState } from 'react'
import { TrackTimeBox , Time} from './TimeTrack.style';
import { CaretRightOutlined ,PauseCircleOutlined} from '@ant-design/icons';
import { Link as ApiLink} from '@api';
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import routes from '@routes';
import { getActiveTimer ,startTimer,stopTimer,setTime,incrementTime } from '../../../store/reducers/timerReducer';
import { useSelector, useDispatch } from 'react-redux';


const GlobalTimer = ({id}) => {
  // const [time,setTime]=useState(0)
  const dispatch = useDispatch()
  const {isRunning, time, data} = useSelector(state => state.timer)
 
  React.useEffect(()=>{
    dispatch(getActiveTimer())
  },[])

  React.useEffect(()=>{
    let interval = null; 
    if (isRunning) {
      interval = setInterval(() => {
        dispatch(incrementTime())
      }, 1000);
    } else {
      clearInterval(interval);
      dispatch(setTime(0))
    }
    return () => {
      clearInterval(interval);
    };
  },[isRunning])
  

  

  return (
    <></>
  )
}

export default GlobalTimer