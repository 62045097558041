import { useHistory } from "react-router-dom";
export default async (endpoint, method = 'GET', body = null, headers = {}) => {
  const token = JSON.parse(localStorage.getItem('app_token'))?.token || false
  const language = JSON.parse(localStorage.getItem('lmt_language')) || 'en'


  headers['Content-Type'] = `application/json`
  headers['Accept'] = `application/json`
  headers['Content-Language'] = language
  if (token) {
    headers['Authorization'] = `Bearer ${token.token}`
  }
  if (body) {
    body = JSON.stringify(body)
  }


  var request = await fetch(`/api/${endpoint}`,{
    method,
    headers,
    body
  })

  if(request.status == 401 && token) {
      localStorage.removeItem('app_token')
      window.location.href = "/login";
  }

  var data = await request.json()
  return  {data,request}
}
