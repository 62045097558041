import fetch from '../utilities/fetch'


const Order = {
  getAll: (params ='')   => fetch(`orders${params}`),
  get: (id, params ='')  => fetch(`orders/${id}${params}`),
  create: (body)    	        => fetch('orders','POST',body),
  delete: (id)      	        => fetch(`orders/${id}`,'DELETE'),
  update: (id,body) 	        => fetch(`orders/${id}`,'PATCH',body),
  search: (text)                => fetch(`orders?search=${text}`),
  addLinks: (id, body)          => fetch(`orders/${id}/addlinks`,'PUT', body),
  check: (id)                   => fetch(`orders/${id}/check`),
  export: (id, withTexts)       => fetch(`orders/${id}/export?with_texts=${withTexts}`)
}

export default Order
