import fetch from '../utilities/fetch'


const Translation = {
  getAll: ()        => fetch('system/translations'),
  search: (text)    => fetch(`system/translations/search?text=${text}`),
  createOrUpdate: (body)    => fetch('system/translations','POST', body),
  delete: (id)      => fetch(`system/translations/${id}`,'DELETE'),
  getByKeys:     (keys) => fetch(`system/translations/keys`,'POST',keys),
  // update: (id,body) => fetch(`users/${id}`,'POST',body)
}

export default Translation