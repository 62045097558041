import fetch from '../utilities/fetch'

const Link = {
  getAll: (params ='')           => fetch(`links${params}`) ,
  get: (id)      	                    => fetch(`links/${id}`),
  create: (body)    	                => fetch('links','POST',body),
  delete: (id)      	                => fetch(`links/${id}`,'DELETE'),
  update: (id,body) 	                => fetch(`links/${id}`,'PATCH',body),
  updateWorkflow: (id, body)            => fetch(`links/${id}/update_workflow`,'PUT',body),
  getWorkflowData: (id)                 => fetch(`links/${id}/get_workflow_data`),
  getRevisions: (id)                    => fetch(`links/${id}/revisions`),
  getForumsForCriteria: (id, body)      => fetch(`links/${id}/forums_for_criteria`, 'POST', body),
  reportProblem: (id, body)             => fetch(`links/${id}/report_problem`,'PUT', body),
  saveText: (id, body)                  => fetch(`links/${id}/save_text`, 'POST', body),
  trackActive: ()                       => fetch(`links/timers/active`, 'GET'),
  trackAll: (id)                        => fetch(`links/${id}/timers`, 'GET'),
  trackStart: (id)                      => fetch(`links/${id}/timers/start`, 'GET'),
  trackStop: (id)                       => fetch(`links/${id}/timers/stop`, 'GET'),
  trackRemove: (id)                     => fetch(`links/timers/${id}`, 'DELETE'),
  trackUpdate: (id,body)                => fetch(`links/timers/${id}`, 'PATCH', body)
}

export default Link
