import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Translation from '@api/translation.api';

export const getStaticTranslations = createAsyncThunk('static/translations', async (keys, thunkApi) => {
  const {data,request} = await Translation.getByKeys(keys)
 return data;
})



const initialState = {
  page:{},
  static:{}
}
const translateReducer = createSlice({
  name: "translations",
  initialState,
  reducers: {
    setPageTranslate(state, {payload}) {state.page=payload}
  },
  
  extraReducers: {
    [getStaticTranslations.fulfilled]: (state,{payload}) => {
      state.static = payload
    }
  }
});

export const { setPageTranslate } = translateReducer.actions
export default translateReducer.reducer