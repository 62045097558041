import React, { useState } from 'react'
import { WidgetBarBox } from './WidgetBar.style';
import TimeTrack from "@components/TimeTrack/TimeTrack";
import { useSelector } from 'react-redux';



const WidgetBar = () => {
  const {isRunning,time, data} = useSelector(state => state.timer)

  return (
    <>
      { isRunning &&
        <WidgetBarBox>
            <TimeTrack widget running={isRunning}/>
        </WidgetBarBox>
      }
    </>
  )
}

export default WidgetBar