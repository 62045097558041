import React from 'react'
import {PageHeader, Spin} from 'antd';

const PageLayout = ({children,header,loading = false}) => {
  return (
    <>
        {!loading ?
            <>
                <PageHeader
                    style={{
                        background:'#fff'
                    }}
                    className="site-page-header"
                    title="All users"
                    onBack={() => window.history.back()}
                    {...header}
                > 
                    {header.body}
                </PageHeader>
                <div
                    style={{
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </div>
                
            </>
        :
            <div style={{
                margin:'20px 0',
                marginBottom: '20px',
                padding: '30px 50px',
                textAlign: 'center',
                borderRadius: '4px'
            }}>
                <Spin style={{margin:'50px auto'}} size="large" />
            </div>
        }
    </>
  )
}

export default PageLayout
