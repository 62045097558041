import React from 'react'
import { TrackTimeBox , Time,ActionBtn} from './TimeTrack.style';
import { PlayCircleTwoTone ,CloseCircleTwoTone} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import routes from '@routes';
import { startTimer,stopTimer} from '../../../store/reducers/timerReducer';
import { useSelector, useDispatch } from 'react-redux';


const TimeTrack = (props) => {
  const dispatch = useDispatch()
  const { time, data } = useSelector(state => state.timer)

  const toggleTimer = async () => {
    if(props.running)
      dispatch(stopTimer(data.link.id))
    else
      dispatch(startTimer(props.id))
  }
  const timeBox = (time) => {
    let startTime  = time ? time : props.defaultTime

    return(
      <Time>
        <span className="digits">
          {("0" + Math.floor(startTime / 60 / 60)).slice(-2)}:
        </span>
        <span className="digits">
          {("0" + Math.floor((startTime / 60) % 60)).slice(-2)}:
        </span>
        <span className="digits">
          {("0" + Math.floor(startTime % 60)).slice(-2)}
        </span>
      </Time>
    )
  }

  return (
    <TrackTimeBox>

      <ActionBtn onClick={toggleTimer}>
        {
          props.running ?
            <CloseCircleTwoTone twoToneColor="red"/> :
            <PlayCircleTwoTone twoToneColor="#52c41a"/>
        }
      </ActionBtn>

      { props.running ?
         props.widget ?
          <Link to={reverse(routes.links.single.show, { linkId: data.link.id })} title={data.link.link_name}>
            {timeBox(time)}
          </Link>
          :
          timeBox(time)
        :
        timeBox(0)
      }

    </TrackTimeBox>

  )
}

TimeTrack.defaultProps = {
  id: 0,
  running:false,
  widget:false,
  defaultTime:0
}

export default TimeTrack
