import styled from "styled-components"
import { CaretRightOutlined } from '@ant-design/icons';



export const TrackTimeBox = styled.div`
  /* position: fixed;
  right:50px;*/
  display:flex;
  align-items:center;
`



export const Time = styled.div`
  margin-left:10px;
`
export const ActionBtn = styled.div`
  cursor: pointer;
`

