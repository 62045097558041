import styled from 'styled-components'
import { Layout } from 'antd';
const {Header} =Layout


export const StyledHeader = styled(Header)`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  padding:0 50px;
  justify-content:flex-end;
  z-index: 1;
`

export const Controls = styled.div`
  display:flex;
  margin-right:-35px;
`
export const StyledDropdown = styled.div`
  cursor: pointer;
  padding: 0 10px;
  &:hover{
    background-color:#eee;
  }
`
