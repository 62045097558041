import styled from 'styled-components'
import { Layout } from 'antd';




export const Logo = styled.div`
 display: flex;
 align-items:center;
 justify-content:center;
 color:#fff;
 height: 60px;
 font-size:20px;
`
