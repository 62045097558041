import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


const initialState = {
  lang:''
}
const settingsReducer = createSlice({
  name: "me",
  initialState,
  reducers: {
    setLanguage(state, {payload}) {state.lang=payload}
  },
  extraReducers: {
  },
});


export const { setLanguage } = settingsReducer.actions
export default settingsReducer.reducer