import { configureStore } from '@reduxjs/toolkit'
import auth from './reducers/authReducer'
import me from './reducers/meReducer'
import timer from './reducers/timerReducer'
import app from './reducers/app'


let store = configureStore({
  reducer: {
    auth,
    me,
    timer,
    app
  }
})

export default store