import React, {Children, cloneElement} from 'react'
import {useSelector} from "react-redux";
import {message} from "antd";

const Access = ({ children, forRoles=[],exceptRoles=[],...props }) => {
    const currentUser = useSelector(state=>state.me)
    let userRoles = currentUser?.data?.roles
    userRoles = userRoles.map(role=>role.name)

    const childrenWithProps = Children.map(children, child => {
        return cloneElement(child, props)
    })

    if (forRoles.length && !userRoles.some(role => forRoles.includes(role)) ||
        exceptRoles.length && userRoles.some(role => exceptRoles.includes(role)))
        return <></>

    return <>{childrenWithProps}</>

}

export default Access
