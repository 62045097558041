import React from 'react'
import {Dropdown} from 'antd';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDropdown } from '../styled';
import uaFlag from './icons/ukraine.svg'
import enFlag from './icons/uk.svg'
import deFlag from './icons/germany.svg'
import { setLanguage } from '../../../../store/reducers/app/settingsReducer';


const LanguageSelector = () => {
  const dispatch = useDispatch()
  const user = useSelector(state=>state.auth.user)
  const history = useHistory()
  const [activeLang,setActiveLang] = React.useState(JSON.parse(localStorage.getItem('lmt_language')) || "en")
  
  React.useEffect(()=>{
    dispatch(setLanguage(activeLang))
    localStorage.setItem('lmt_language', JSON.stringify(activeLang));
  },[activeLang])

  const onClick = function ({ key }) {
    setActiveLang(key)
  };

  const iconsSelect = (flag) => {
    var src = enFlag
    switch (flag) {
      case 'en':
        src = enFlag
        break;
      case 'de':
        src = deFlag
        break;
      case 'ua':
        src = uaFlag
        break;
    }
    return  <img src={src} width="15" height="15"/>
  }
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="en">
        {iconsSelect('en')} English
      </Menu.Item>
      <Menu.Item key="de">
        {iconsSelect('de')} Deutsch
      </Menu.Item>
      <Menu.Item  key="ua">
        {iconsSelect('ua')} Українська
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown placement="bottomCenter" overlay={menu}>
      <StyledDropdown>
        {iconsSelect(activeLang)}
      </StyledDropdown>
    </Dropdown>
  )
}

export default LanguageSelector
