import React from 'react'
import loadable from '@loadable/component'
import { Switch, Route, Router, Redirect } from 'react-router-dom'
import routes from './index'
import PrivateRoute from '@components/PrivateRoute'
import DasboardLayout from '../layouts/DasboardLayout'
import { admin, qa,implementer } from '@configs/roles';
import { SystemSettings, SystemTranslations } from '../pages/System/'



const Login = loadable(() => import('../pages/Login/Login'))
const Dashboard = loadable(() => import('../pages/Dashboard/Dashboard'))

const GeneralStatistics = loadable(() => import('../pages/Statistics/General/GeneralStatistics') )
const TeamStatistics = loadable(() => import('../pages/Statistics/Team/TeamStatistics') )
const Scores = loadable(() => import('../pages/Statistics/Scores/Scores'))
const PerformanceReport = loadable(() => import('../pages/Statistics/Report/PerformanceReport'))

const MyProfile = loadable(() => import('../pages/UserProfile/UserProfile'))

const AllCustomers = loadable(() => import('../pages/Customers/Customers'))
const SingleCustomer = loadable(() => import('../pages/Customers/Single/Single'))
const AllOrders = loadable(() => import('../pages/Orders/Orders'))
const SingleOrder = loadable(() => import('../pages/Orders/Single/Single'))
const AddOrderLinks = loadable(() => import('../pages/Orders/Single/AddLinks'))

const AllLinks = loadable(() => import('../pages/Links/Links'))
const SingleLink = loadable(() => import('../pages/Links/Single/Single'))

const AllPackages = loadable(() => import('../pages/Packages/Packages'))
const SinglePackage = loadable(() => import('../pages/Packages/Single/Single'))

const AllForums = loadable(() => import('../pages/Forums/Forums'))
const SingleForum = loadable(() => import('../pages/Forums/Single/Single'))
const Accounts = loadable(() => import('../pages/Accounts/Accounts'))
const SingleAccount = loadable(() => import('../pages/Accounts/Single/Single'))
const EmailAddresses = loadable(() => import('../pages/EmailAddresses/EmailAddresses'))
const SingleEmailAddress = loadable(() => import('../pages/EmailAddresses/Single/Single'))


const AllUsers = loadable(() => import('../pages/Users/All/Users'))
const UserRoles = loadable(() => import('../pages/Users/Roles/Roles'))
const SingleProfile = loadable(() => import('../pages/Users/UserProfile/UserProfile'))


const Stripe = loadable(() => import('../pages/Stripe/Stripe'))

const useRoutes = (authenticated) => {
  return (
    <>
      <Switch>
        <Route exact path='/login' component={Login} />
        {!authenticated && <Redirect to='/login' />}
        <DasboardLayout>
          <PrivateRoute path={routes.dashboard} component={Dashboard} />

          <PrivateRoute path={routes.customers.all} exact component={AllCustomers} />
          <PrivateRoute path={routes.customers.single} exact component={SingleCustomer} />

          <PrivateRoute path={routes.orders.all} roles={[admin,qa]} exact component={AllOrders} />
          <PrivateRoute path={routes.orders.single.show} exact component={SingleOrder} />
          <PrivateRoute path={routes.orders.single.addlinks} component={AddOrderLinks} />

          <PrivateRoute path={routes.packages.all} roles={[admin]} component={AllPackages} />
          <PrivateRoute path={routes.packages.single} component={SinglePackage} />

          <PrivateRoute path={routes.forums.all} component={AllForums} />
          <PrivateRoute path={routes.forums.single} exact component={SingleForum} />

          <PrivateRoute path={routes.accounts.all} component={Accounts} />
          <PrivateRoute path={routes.accounts.single} component={SingleAccount} />

          <PrivateRoute path={routes.email_addresses.all} component={EmailAddresses} />
          <PrivateRoute path={routes.email_addresses.single} component={SingleEmailAddress} />

          <PrivateRoute path={routes.links.all} exact component={AllLinks} />
          <PrivateRoute path={routes.links.single.show} exact component={SingleLink} />

          <PrivateRoute path={routes.users.all} roles={[admin]} component={AllUsers} exact />
          <PrivateRoute path={routes.users.single} roles={[admin]} component={SingleProfile} />
          <PrivateRoute path={routes.roles} roles={[admin]} component={UserRoles} />

          <PrivateRoute path={routes.me.self} component={MyProfile} />

          <PrivateRoute path={routes.statistics.general} component={GeneralStatistics} />
          <PrivateRoute path={routes.statistics.team} component={TeamStatistics} />
          <PrivateRoute path={routes.statistics.scores} component={Scores} />
          <PrivateRoute path={routes.statistics.report} component={PerformanceReport} />

          <PrivateRoute path={routes.system.settings} roles={[admin]} component={SystemSettings} />
          <PrivateRoute path={routes.system.translate} roles={[admin]} component={SystemTranslations} />


          <PrivateRoute path={'/stripe'} component={Stripe} />
        </DasboardLayout>

      </Switch>
    </>
  )
}

export default useRoutes
