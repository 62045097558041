import { combineReducers } from 'redux';
import translateReducer from './translateReducer';
import settingsReducer from './settingsReducer';
import { createSlice } from '@reduxjs/toolkit';


const rootReducer = combineReducers({
  translations: translateReducer,
  settings: settingsReducer,
});

export default rootReducer