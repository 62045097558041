import React from 'react'
import {Dropdown,Avatar} from 'antd';
import { Menu } from 'antd';
import { Link,useHistory } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import routes from '@routes';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../store/reducers/authReducer';
import { StyledDropdown } from '../styled';

const User = () => {
  const dispatch = useDispatch()
  const user = useSelector(state=>state.me.data)
  const history = useHistory()

  
  const menuActionHandler =  (e) => {
    if(e.key=="logout") {
      dispatch(logout()) 
      history.push('/login');
    }
    if(e.key=="my-profile") history.push(routes.me.self);
  }
  
  const menu = (
    <Menu onClick={menuActionHandler} >
      <Menu.Item icon={<UserOutlined />} key="my-profile">
        My Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />}  key="logout">
         <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
  
  return (
    <Dropdown placement="bottomCenter" overlay={menu}>
      <StyledDropdown>
        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {user?.name?.charAt(0).toUpperCase()}
        </Avatar> 
          {"  "+user.name}
      </StyledDropdown>
    </Dropdown>
  )
}

export default User
