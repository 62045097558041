import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Link } from '@api';

export const getActiveTimer = createAsyncThunk('timer/active', async (user, thunkApi) => {
  const {data} = await Link.trackActive()
  return data
})


export const startTimer = createAsyncThunk('timer/start', async (user, thunkApi) => {
  const {data} = await Link.trackStart(user)
  return data 
})

export const stopTimer = createAsyncThunk('timer/stop', async (user, thunkApi) => {
  const {data} = await Link.trackStop(user)
  return data 
})


const initialState = {
  isRunning:false,
  data:false,
  time: 0
}
const timer = createSlice({
  name: "timer", 
  initialState,
  reducers: {
    setTime:(state,action) => {
      state.time = action.payload
    },
    incrementTime:(state,action) => {
      state.time++
    }
  },
  extraReducers: {
    [getActiveTimer.rejected]: (state,action) => {
      state.isRunning = false
    },
    [getActiveTimer.fulfilled]: (state,action) => {
      state.isRunning = action.payload.isRunning,
      state.time = action.payload.diffInSeconds,
      state.data = action.payload
    },
    [startTimer.pending]: (state,action) => {
      state.isRunning = false
    },
    [startTimer.fulfilled]: (state,action) => {
      state.isRunning = action.payload.isRunning,
      state.time = action.payload.diffInSeconds,
      state.data = action.payload
    },
    [stopTimer.fulfilled]: (state,action) => {
      state.isRunning = action.payload.isRunning,
      state.data = false,
      state.time = 0
    }
  },
});

export const { setTime, incrementTime } = timer.actions
export default timer.reducer

