import { include } from 'named-urls'

export default {
  dashboard: '/dashboard',
  me: include('/my-profile', {
    self: ''
  }),
  customers: include('/customers', {
    all: '',
    single: ':customerId',
  }),
  orders: include('/orders', {
    all: '',
    single: include(':orderId/', {
      show: '',
      addlinks: 'addlinks/',
      check: 'check/',
      export: 'export/'
    })
  }),
  forums: include('/forums', {
    all: '',
    single: ':forumId',
  }),
  accounts: include('/accounts', {
    all: '',
    single: ':accountId',
  }),
  email_addresses: include('/email_addresses', {
    all: '',
    single: ':emailId',
  }),
  packages: include('/packages', {
    all: '',
    single: ':packageId',
  }),
  users: include('/users', {
    all: '',
    single: ':userId',
  }),
  links: include('/links', {
    all: '',
    create: 'create/',
    single: include(':linkId(\\d+)/', {
      show: '',
      revisions: 'revisions',
      qw_workflow: 'qw_workflow',
      qr_workflow: 'qr_workflow',
      qi_workflow: 'qi_workflow',
      aw_workflow: 'aw_workflow',
      ar_workflow: 'ar_workflow',
      ai_workflow: 'ai_workflow',
      pb_workflow: 'pb_workflow',
      get_workflow_data: 'get_workflow_data',
      update_workflow: 'update_workflow',
      report_problem: 'report_problem'
    }),
  }),
  roles: '/roles',
  statistics: include('/statistics', {
    general: 'general',
    team: 'team',
    scores: 'scores',
    report: 'report'
  }),
  system: include('/system', {
    settings: 'settings',
    translate: 'translations'
  }),
}
